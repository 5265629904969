import React from 'react';
import { Link} from '@reach/router';

import nft2 from '../images/nft2.jpeg';
import phantom from '../images/phantom.jpg';
import la from '../images/la.jpg';
import studio from '../images/studio.jpg';
import FadeInSection from "./FadeInSection";
import logo from '../images/logo.png';
import music from '../images/musical-note.png';
import Hero from './Hero'
import Services from './Services'

const Home = () => {
    return ( 
        <div>
        <Hero/>
        {/* <Services/> */}
        </div>
        
    )
}

export default Home;