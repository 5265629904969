import React from 'react';
import logo from '../images/1331s.PNG'

const Loading = () => {
    return (
        <div id="loadingpage">
            <img className="App-logo-spin App-logo" src={logo} alt="logo"/>
        </div>
    )
}

export default Loading;