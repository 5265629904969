import React from 'react';
import './Merch.css'; 
import logo from '../images/1331s.PNG';

// Mock data for the merchandise items
const merchandiseItems = [
  {
    id: 1,
    name: 'T-Shirt',
    price: '19.99',
    imageUrl: 'path-to-tshirt-image.jpg',
    description: 'Comfortable cotton t-shirt with logo',
    marketplaceUrl: 'https://www.tiktok.com/marketplace/hat'
  },
  {
    id: 2,
    name: 'Hat',
    price: '15.99',
    imageUrl: 'path-to-hat-image.jpg',
    description: 'Stylish hat with embroidered logo',
    marketplaceUrl: 'https://www.tiktok.com/marketplace/hat'
  },
  {
    id: 3,
    name: 'Sweatshirt',
    price: '15.99',
    imageUrl: 'path-to-hat-image.jpg',
    description: 'Stylish hat with embroidered logo',
    marketplaceUrl: 'https://www.tiktok.com/marketplace/hat'
  },
  {
    id: 4,
    name: 'Sweatpants',
    price: '15.99',
    imageUrl: '',
    description: 'Stylish hat with embroidered logo',
    marketplaceUrl: 'https://www.tiktok.com/marketplace/hat'
  },
  {
    id: 5,
    name: 'Flag Banner',
    price: '15.99',
    imageUrl: '',
    description: 'Stylish hat with embroidered logo',
    marketplaceUrl: 'https://www.tiktok.com/marketplace/hat'
  },
  {
    id: 6,
    name: 'Custom Jacket',
    price: '15.99',
    imageUrl: '',
    description: 'Stylish hat with embroidered logo',
    marketplaceUrl: 'https://www.tiktok.com/marketplace/hat'
  }
  // ... add more items as needed
];

const Merch = () => {
  return (
    <div className="merchandise-page">
      <br/>
      <h1>Merch on Merch </h1>
      <div className="merchandise-grid">
        {merchandiseItems.map(item => (
          <div className="merchandise-item" key={item.id}>
            <img src={logo} alt={item.name} className="item-image" />
            <h3 className="item-name">{item.name}</h3>
            <br/>
            <p className="item-price">${item.price}</p>
            <button className="buy-now-btn">
            <a href={item.marketplaceUrl} target="_blank" rel="noopener noreferrer" className="buy-now-btn">
              Buy Now
            </a>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Merch;